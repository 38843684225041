<template>
  <CRow>
    <CCol md="3">
      <CCard>
        <CCardHeader>
          <h5>Alle Pipelines</h5>
        </CCardHeader>

        <CCardBody>
          <CDataTable
            :items="tableData"
            :fields="getTableFields()"
			:noItemsView="{ noResults: 'Keine Suchergebnisse verfügbar', 
            noItems: 'Keine Pipelines erstellt' }"
            hover
            striped
            bordered
            fixed
            :loading="tableShowLoading"
          >
            <template #edit_item="{item, index}">
              <td class="py-2">
                <CButton
                  @click="editPipeline(item)"
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                >
                  Editieren
                </CButton>
              </td>
            </template>

            <template #delete_item="{item, index}">
              <td class="py-2">
                <CButton
                  @click="deletePipeline(item)"
                  color="danger"
                  variant="outline"
                  square
                  size="sm"
                >
                  Löschen
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>

        <CCardFooter>
          <CRow>
            <CCol md="12">
              <CButton class="w-100" color="success" v-show="!pipelineEditEnable" @click="addPipeline()">
                Pipeline hinzufügen
              </CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>

    <CCol md="9" v-show="pipelineEditEnable">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol md="4">
              <CInput
                v-model="name"
                placeholder="Pipeline name"
                class="mb-0"
              />
              <label class="text-danger" v-show="visibleNameError">
                Bitte Pipeline-Name eingeben
              </label>
            </CCol>
            <CCol md="4"></CCol>
            <CCol md="4" class="text-right">
              <CButton color="success" @click="showStepModal('add')">Stufe hinzufügen</CButton>
            </CCol>
          </CRow>
        </CCardHeader>

        <CCardBody style="overflow: scroll;">
          <table class="table table-striped pipeline-table">
            <thead class="thead-dark">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Wahrscheinlichkeit</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            </thead>
            <draggable v-model="list" tag="tbody">
              <tr v-for="(item, index) in list" :key="item.name + '-' + index">
                <td>{{ item.name }}</td>
                <td>{{ item.probability}}</td>
                <td class="py-2">
                  <CButton
                    @click="editStep(item, index)"
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                  >
                    Editieren
                  </CButton>
                </td>
                <td class="py-2">
                  <CButton
                    color="danger"
                    variant="outline"
                    square
                    size="sm"
                    @click="list.splice(index, 1)"
                  >
                    Löschen
                  </CButton>
                </td>
              </tr>
            </draggable>
          </table>
          <label class="text-danger" v-show="visibleStepsError">Bitte fügen Sie mindestens einen Schritt hinzu</label>
        </CCardBody>

        <CCardFooter>
          <CRow>
            <CCol md="12" class="text-center">
              <CButton
                color="success"
                class="w-25"
                @click="submitPipeline()"
              >Speichern
              </CButton>
              <CButton
                color="secondary"
                class="w-25 ml-5"
                @click="pipelineEditEnable = false"
              >Abbrechen
              </CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>

    <CCol md="12">
      <CForm @submit="submitStep">
        <CModal
          title="Stufe"
          size="sm"
          :show.sync="visibleModal"
          :closeOnBackdrop="false"
        >
          <CRow>
            <CCol md="12">
              <CInput
                placeholder="Name"
                v-model="stepName"
                required
              />
            </CCol>
            <CCol md="12">
              <CInput
                type="number"
                placeholder="Wahrscheinlichkeit"
                v-model="probability"
                required
              />
            </CCol>
          </CRow>

          <div slot="footer">
            <CButton
              class="mr-3"
              color="dark"
              variant="outline"
              @click="visibleModal = false"
            >
              Abbrechen
            </CButton>
            <CButton
              color="success"
              type="submit"
            >
              Speichern
            </CButton>
          </div>
        </CModal>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
	import axios from 'axios'
	import draggable from "vuedraggable"

	export default {
		components: {
			draggable
		},
		mounted() {
			this.loadPipelines();
		},
		data() {
			return {
				visibleModal: false,
				tableData: [],
				tableShowLoading: false,
				name: '',
				list: [],
				dragging: false,
				stepModalMode: null,
				stepName: '',
				probability: '',
				pipelineEditEnable: false,
				visibleNameError: false,
				visibleStepsError: false,
				submitted: false,
				selectedStepIndex: null,
				selectedPipelineId: null,
				pipelines: null,
			};
		},
		methods: {
			async loadPipelines() {
				const ret = await axios.get('/pipeline')
				this.pipelines = ret.data || []
				this.tableData = []

				if (ret.data && ret.data.length > 0) {
					this.tableData = ret.data.map(item => {
						return {
							id: item.id,
							name: item.name
						}
					})
				}
			},
			getTableFields() {
				return [
					{
						key: 'name',
						label: 'Name',
					},
					{
						key: 'edit_item',
						label: '',
						_style: 'width:1%',
						sorter: false,
						filter: false
					},
					{
						key: 'delete_item',
						label: '',
						_style: 'width:1%',
						sorter: false,
						filter: false
					}
				]
			},
			// show step modal
			showStepModal(mode) {
				this.stepModalMode = mode
				this.visibleModal = true
				this.initializeStep()
			},
			// initialize the step input fields
			initializeStep() {
				this.stepName = ''
				this.probability = ''
			},
			// when click edit on step of pipeline
			editStep(step, index) {
				this.selectedStepIndex = index
				this.stepName = step.name
				this.probability = step.probability
				this.visibleModal = true
				this.stepModalMode = 'update'
			},
			// when click save on step modal
			submitStep(evt) {
				evt.preventDefault();

				const data = {
					name: this.stepName,
					probability: this.probability,
				}

				if (this.stepModalMode === 'add') {
					this.list.push(data)
				} else if (this.stepModalMode === 'update') {
					this.list[this.selectedStepIndex].name = data.name
					this.list[this.selectedStepIndex].probability = data.probability
				}

				this.visibleModal = false
			},
			addPipeline() {
				this.submitted = false
				this.selectedPipelineId = null
				this.pipelineEditEnable = true
				this.list = []
				this.name = ''
			},
			// when click edit on pipeline
			editPipeline(item) {
				this.submitted = false
				const pipeline = this.pipelines && this.pipelines.find(entity => entity.id === item.id)

				if (pipeline) {
					this.selectedPipelineId = pipeline.id
					this.name = pipeline.name
					this.list = []

					pipeline.pipelineSteps.sort((a, b) => {
						return a.order - b.order
					})

					pipeline.pipelineSteps && pipeline.pipelineSteps.map(step => {
						this.list.push({
							id: step.id,
							name: step.name,
							probability: step.probability,
						})
					})

					this.pipelineEditEnable = true
				}
			},
			// when click delete on pipeline
			async deletePipeline(item) {
				try {
					await axios.delete(`/pipeline/${item.id}`)

					this.tableData = this.tableData.filter(entity => entity.id !== item.id)
					this.pipelines = this.pipelines.filter(entity => entity.id !== item.id)

					this.pipelineEditEnable = false
				} catch (e) {
					console.log(e)
				}
			},
			// when click save pipeline
			async submitPipeline() {
				this.submitted = true

				if (this.name && this.name.trim() !== '') {
					if (this.list && this.list.length > 0) {
						this.list && this.list.map((item, index) => {
							item['orderNumber'] = index + 1;
							item['probability'] = parseFloat(item['probability'])
						})
						const data = {
							name: this.name,
							pipelineSteps: this.list
						}

						if (!this.selectedPipelineId) { // when add pipeline
							const ret = await axios.post('/pipeline', data)
							this.pipelines.push(ret.data)

              if (!(this.tableData && this.tableData.length > 0)) {
              	this.tableData = []
              }

							this.tableData.push({
								id: ret.data.id,
								name: ret.data.name
							})
						} else { // when edit pipeline
							const ret = await axios.put(`/pipeline/${this.selectedPipelineId}`, data)
							const index = this.pipelines.findIndex(pipeline => pipeline.id === ret.data.id)
							this.pipelines.splice(index, 1, ret.data)
							const ii = this.tableData.findIndex(entity => entity.id === ret.data.id)
							this.tableData[ii].name = ret.data.name
						}

						this.pipelineEditEnable = false
					} else {
						this.visibleStepsError = true
					}
				} else {
					this.visibleNameError = true
				}
			},
		},
		watch: {
			name: function (val) {
				if (this.submitted) {
					this.visibleNameError = !(val && val.trim() !== '')
				}
			},
			list: {
				deep: true,
				handler(newList) {
					if (this.submitted) {
						this.visibleStepsError = newList.length === 0
					}
				}
			}
		}
	};
</script>

<style>
  .pipeline {
    width: 100%;
    margin-bottom: 5px;
  }

  .pipeline-table {
    cursor: pointer;
  }
</style>
